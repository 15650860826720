.container{
    display: flex;
    list-style: none;
}

.page{
	padding: 0.5rem;
    border: 1px solid #dcdcdc;
    border-radius: 6px;
    margin-right: 10px;
    cursor: pointer;
}

.pageSele{
    padding: 0.5rem;
    border: 1px solid #dcdcdc;
    height: 1.8rem;
    border-radius: 6px;
    margin-right: 10px;
    margin-top: 20px;
    cursor: pointer;
    vertical-align: middle;
}

.disabled{
    cursor: not-allowed;
    
}

.activePage{
	border: 1px solid #EA6A12;
    font-weight: bold;
    vertical-align: middle;
}

.previous{
    padding: 10px;
    border-radius: 6px;
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
}

.break{
    padding: 10px;
}

.next{
    padding: 10px;
    border-radius: 6px;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.paginate-container{
    display: flex;
    justify-content: center;
}
.paginate{
    margin: 10px 0px;
}

.paginate button{
    margin-right: 10px;
    border: 1px solid gray;
}

.paginate .selected{
    background-color: goldenrod;
    border: gold;
    font-weight: bold;
    width: 30px;
    padding: 5px;
}

.selection{
    border: 1px solid gray;
    padding: 3px 0px;
    border-radius: 2px;
}

.filter-options{
    display: flex;
    align-items: center;
    justify-content: right;
}

.filter-options p{
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 1rem;

}
.gopesh-modal,
.gopesh-overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.gopesh-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2rem;
}

.gopesh-overlay {
  background: rgba(49, 49, 49, 0.8);
}
.gopesh-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
}

.inputFields {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 100%;
}

label {
  width: 200px;
  text-align: left;
  margin-bottom: 0.5rem;
}

input {
  width: 100%;
  height: 30px;
  padding: 5px;
}

:root {
  --gray-color: #fff;
  --box-shadow-button: 0 0 0 1px rgba(50, 50, 93, 0.1),
    0 2px 5px 0 rgba(50, 50, 93, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.07),
    0 1px 2px 0 rgba(0, 0, 0, 0.08);
  --box-shadow-button-hover: 0 0 0 1px rgba(50, 50, 93, 0.1),
    0 2px 5px 0 rgba(50, 50, 93, 0.1), 0 3px 9px 0 rgba(50, 50, 93, 0.08),
    0 1px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
}

.gopesh-btn {
  box-shadow: var(--box-shadow-button);
  border-radius: 4px;
  background-color: rgb(218, 113, 48);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* padding: 25px 50px; */
  padding: 0.5rem 1.5rem;
  font-size: 20px;
  font-weight: 600;
  border: none;
  outline: none;
  font-family: "Nunito";
  transition: all 0.25s ease;
  color: var(--gray-color);
  cursor: pointer;
  letter-spacing: 0.05em;
}

.gopesh-btn:hover {
  box-shadow: var(--box-shadow-button-hover);
  transform: translate(2px, -2px);
  letter-spacing: 0.25em;
  border-radius: 40px;
}

.gopesh{
  width: 100%;
}